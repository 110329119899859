<template>
  <div id="app">
    <header-view />
    <keep-alive>
      <router-view class="routerView" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view class="routerView" v-if="!$route.meta.keepAlive" />
    <footer-view />
  </div>
</template>

<script>
import HeaderView from "@/views/Header/index.vue";
import FooterView from "@/views/Footer/index.vue";

export default {
  components: { HeaderView, FooterView },
};
</script>

<style lang="stylus"></style>
