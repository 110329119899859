import axios from "axios";

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000
})

http.interceptors.request.use((config) => {
    return config
}, (error) => {
    Promise.reject(error)
})

http.interceptors.response.use((response) => {
    if (response.status === 200) {
        return Promise.resolve(response.data)
    } else {
        return Promise.resolve(response)
    }
}, (error) => {
    return Promise.reject(error)
})

export default http