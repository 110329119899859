const state = {
    // 公司信息
    companyData: {},
    // 部门信息
    departmentData: {},
    // 售后服务信息
    saleServeData: {},

    // 姓名
    name: '',
    // 电话
    tel: "",
    // 邮箱
    email: "",
    // 备注
    remark: '',

    // 显示选择省/市/区
    showSelectType: "",

    // 省
    provice: null,
    // 市
    city: null,
    // 区
    area: null,

    // 姓名错误信息
    nameError: '',
    // 电话错误信息
    telError: '',
    // 邮箱错误信息
    emailError: '',
    // 地区错误信息
    positionError: '',

    // 提示框关闭倒计时
    duration: 0,
    // 提示框内容
    message: '提交成功'

}

const mutations = {
    setCompanyData(state, data) {
        state.companyData = data
    },
    setDepartmentData(state, data) {
        state.departmentData = data
    },
    setSaleServeData(state, data) {
        state.saleServeData = data
    },

    setName(state, name) {
        state.name = name
    },

    setTel(state, tel) {
        state.tel = tel
    },

    setEmail(state, email) {
        state.email = email
    },

    setRemark(state, remark) {
        state.remark = remark
    },

    setShowSelectType(state, type) {
        state.showSelectType = type
    },

    setProvice(state, provice) {
        state.provice = provice
    },

    setCity(state, city) {
        state.city = city
    },

    setArea(state, area) {
        state.area = area
    },

    setNameError(state, error) {
        state.nameError = error
    },

    setTelError(state, error) {
        state.telError = error
    },

    setEmailError(state, error) {
        state.emailError = error
    },

    setPositionError(state, error) {
        state.positionError = error
    },

    setDuration(state, duration) {
        state.duration = duration
    },

    setMessage(state, message) {
        state.message = message
    }
}

export default { namespaced: true, state, mutations }