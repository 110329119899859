const state = {
    pageSize: 30,
    currentPage: 1,
    totalPage: 0,
    dataList: [],
}

const mutations = {
    setCurrentPage(state, page) {
        state.currentPage = page
    },
    setDataList(state, list) {
        state.dataList = list
    },
    setTotalPage(state, totalPage) {
        state.totalPage = totalPage
    }
}

export default { namespaced: true, state, mutations }