import http from "@/http";

// 获取宣传轮播数据
export function getCompanyIdeaList() {
    return http({
        method: 'GET',
        url: '/company/getIdeaList'
    })
}

// 获取设备列表
export function getDeviceList(params) {
    return http({
        method: 'POST',
        url: '/product/getProducts',
        data: params
    })
}

// 根据设备名称或型号查询设备列表
export function searchDeviceList(params) {
    return http({
        method: 'GET',
        url: '/product/fuzzyQuery',
        params: params
    })
}

// 根据产品ID获取产品详情
export function getDevice(id) {
    return http({
        method: 'GET',
        url: '/product/getProductById',
        params: { productId: id }
    })
}

// 获取热门产品列表
export function getHotDeviceList() {
    return http({
        method: 'POST',
        url: '/product/getHotProducts'
    })
}

// 获取手机端热门产品
export function getPhoneHostDeviceList() {
    return http({
        method: 'POST',
        url: '/product/getPhoneHomeProducts'
    })
}

// 获取公司基本信息
export function companyInfo() {
    return http({
        method: 'GET',
        url: '/company/getCompanyInfo',
    })
}
