<template>
  <div class="phone">
    <!-- 产品展示 -->
    <div class="menu">
      <div
        class="menu-title"
        :class="{ 'menu-title-open': openMenu === 1 }"
        @click="menuClicked(1)"
      >
        <span>产品展示</span>
        <span>+</span>
      </div>
      <div class="menu-content" v-show="openMenu === 1">
        <div class="menu-content-item" @click="toProductList(1)">
          材料行业 智能感知设备
        </div>
        <div class="menu-content-item" @click="toProductList(2)">
          智能制造 解决方案
        </div>
        <div class="menu-content-item" @click="toProductList(3)">
          环境保护 环保设备
        </div>
      </div>
    </div>
    <!-- 业绩动态 -->
    <div class="menu">
      <div
        class="menu-title"
        :class="{ 'menu-title-open': openMenu === 2 }"
        @click="menuClicked(2)"
      >
        <span>业绩动态</span>
        <span>+</span>
      </div>
      <div class="menu-content" v-show="openMenu === 2">
        <div class="menu-content-item" @click="toAchievement(1)">公司新闻</div>
        <div class="menu-content-item" @click="toAchievement(2)">业绩动态</div>
      </div>
    </div>
    <!-- 关于我们-->
    <div class="menu">
      <div
        class="menu-title"
        :class="{ 'menu-title-open': openMenu === 3 }"
        @click="menuClicked(3)"
      >
        <span>关于我们</span>
        <span>+</span>
      </div>
      <div class="menu-content" v-show="openMenu === 3">
        <div
          class="menu-content-item"
          v-for="item in aboutMenus"
          @click="toAbout(item.type)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>

    <!-- 联系我们-->
    <div class="menu">
      <div
        class="menu-title"
        :class="{ 'menu-title-open': openMenu === 4 }"
        @click="menuClicked(4)"
      >
        <span>联系我们</span>
        <span>+</span>
      </div>
      <div class="menu-content" v-show="openMenu === 4">
        <div class="menu-content-item" @click="toContact">联系我们</div>
      </div>
    </div>

    <!-- 法律&隐私 -->
    <div class="law-privacy">
      <span>法律</span>
      <span>隐私</span>
    </div>

    <!-- 公司名称 -->
    <div class="companyName">北京英斯派克科技有限公司</div>

    <!-- 版权 -->
    <div class="copyright">© 2006-2020</div>

    <!-- 邮编 -->
    <div class="abbreviation">京ICP备13041446号</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openMenu: null,
      // 关于我们子菜单
      aboutMenus: [
        { type: 1, label: "简介" },
        { type: 2, label: "资质" },
        { type: 3, label: "核心价值观" },
        { type: 4, label: "业绩" },
      ],
    };
  },
  methods: {
    menuClicked(index) {
      if (this.openMenu === index) {
        this.openMenu = null;
      } else {
        this.openMenu = index;
      }
    },
    // 跳转到产品展示页
    toProductList(kind) {
      this.$store.commit("product/setProductKind", kind);
      document.documentElement.scrollTop = 0;
      if (this.$route.name !== "product") {
        this.$router.push({ path: `/product` });
      }
    },
    // 跳转到业绩
    toAchievement(type) {
      if (this.$route.name !== "achievement") {
        this.$router.push({ path: `/achievement/${type}` });
      } else {
        if (type != this.$route.params.type) {
          this.$router.push({ path: `/achievement/${type}` });
        }
        document.documentElement.scrollTop = 0;
      }
    },
    // 跳转到关于我们
    toAbout(type) {
      if (this.$route.name !== "about") {
        this.$router.push({ path: `/about/${type}` });
      } else {
        if (type != this.$route.params.type) {
          this.$router.push({ path: `/about/${type}` });
        }
        document.documentElement.scrollTop = 0;
      }
    },
    // 挑战到联系我们
    toContact() {
      document.documentElement.scrollTop = 0;
      if (this.$route.name !== "contact") {
        this.$router.push("/contact");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.phone
  background: #040404
  flex-direction: column
  .menu
    margin-left: 46px
    margin-right: 48px
    display: flex
    flex-direction: column
    border-bottom: 1px solid rgba(255,255,255,0.3)
    .menu-title
      height: 230px
      padding-left: 31px
      cursor: pointer
      display: flex
      align-items: center
      justify-content: space-between
      span
        font-size: 82px
        color: #FFFFFF
        font-weight: bold
        &:last-child
          font-size: 86px
    .menu-title-open
      span
        color: #0185FF
        &:last-child
          transform: rotateZ(45deg)

    .menu-content
      padding-bottom: 53px
      border-bottom: 1px solid rgba(255,255,255,0.5)

      .menu-content-item
        height: 150px
        font-size: 62px
        padding-left: 51px
        color: #FFFFFF
        cursor: pointer
        display: flex
        align-items: center
  // 法律&隐私
  .law-privacy
    margin-left: 76.8px
    margin-top: 62px
    display: flex
    align-items: center
    span
      font-size: 62px
      line-height: 72px
      color: #FFFFFF
      &:first-child
        margin-right: 65px
  // 公司名称
  .companyName
    font-size: 62px
    margin-left: 76.8px
    margin-top: 150px
    color: #FFFFFF

  // 版权
  .copyright
    font-size: 62px
    margin-left: 76.8px
    margin-top: 77px
    color: #FFFFFF

  // 邮编
  .abbreviation
    font-size: 62px
    margin-left: 76.8px
    margin-top: 77px
    margin-bottom: 72px
    color: #FFFFFF
</style>
