import cache from "@/utils/cache";

const state = {
    navList: [
        { label: "首页", path: "/home", name: "home" },
        { label: "产品展示页", path: "/product", name: "product" },
        { label: "业绩动态", path: "/achievement", name: "achievement" },
        { label: "关于我们", path: "/about", name: "about" },
        { label: "联系我们", path: "/contact", name: "contact" },
    ],
    selectNav: cache.getSession("MENU", { label: "首页", path: "/home", name: 'home' }),
}

const mutations = {
    setSelectNav(state, nav) {
        state.selectNav = nav
        cache.saveSession("MENU", nav);
    }
}

export default { namespaced: true, state, mutations }