const cache = {
    saveSession(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    },

    getSession(key, defaultValue = {}) {
        if (sessionStorage.getItem(key) !== null) {
            return JSON.parse(sessionStorage.getItem(key));
        } else {
            return defaultValue;
        }
    },
}

export default cache