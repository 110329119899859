import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home/index.vue")
  },
  {
    path: "/product",
    name: "product",
    meta: { keepAlive: true },
    component: () => import("../views/Product/index.vue"),
  },
  {
    path: "/achievement/:type",
    name: "achievement",
    meta: { keepAlive: true },
    component: () => import("../views/Achievement/index.vue"),
  },
  {
    path: "/about/:type",
    name: "about",
    component: () => import("../views/Abount/index.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact/index.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/Search/index.vue"),
  },
  {
    path: "/productInfo/:id",
    name: "productInfo",
    component: () => import("../views/ProductInfo/index.vue"),
  },
  {
    path: "/newsInfo/:id",
    name: "newsInfo",
    component: () => import("../views/NewsInfo/index.vue"),
  },
  {
    path: '*',
    redirect: '/home'
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
