const state = {
    // 当前显示的设备
    device: {},
    // 相关推荐设备列表
    deviceList: []
}

const mutations = {
    setDevice(state, device) {
        // 制造标准
        const standard = device.constanttemperature || ''
        // 核心技术
        const technology = device.coretechnology || ''
        // 主要技术参数
        const param = device.maintechnicalparameters || ''

        // 产品介绍
        const introduce = device.constanttemperature || '{}'
        // 核心技术
        const coreTechnology = device.coretechnology || '[]'
        // 设备功能
        const func = device.maintechnicalparameters || '[]'

        if (device.kind == 1) {
            device.constanttemperature = standard.split("&#")
            device.coretechnology = technology.split("&#")
            device.maintechnicalparameters = param.split("&#")
        } else {
            device.constanttemperature = JSON.parse(introduce)
            device.coretechnology = JSON.parse(coreTechnology)
            device.maintechnicalparameters = JSON.parse(func)
        }
        state.device = device
    },
    setDeviceList(state, list) {
        state.deviceList = list
    }
}

export default { namespaced: true, state, mutations }