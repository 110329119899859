const state = {

    // 热门设备列表
    hotDeviceList: [],
    // 手机端热门产品
    phoneDeviceList: [],

    // 材料行业智能感知设备列表
    materialDeviceList: []

}

const mutations = {
    setHotDeviceList(state, list) {
        state.hotDeviceList = list
    },
    setPhoneDeviceList(state, list) {
        state.phoneDeviceList = list
    },
    setMaterialDeviceList(state, list) {
        state.materialDeviceList = list
    }
}

export default { namespaced: true, state, mutations }