const state = {
    // 研发历程
    milestoneList: [],

    // 滚动到研发历程
    scrollToMilestone: false
}

const mutations = {
    setMilestoneList(state, list) {
        state.milestoneList = list
    },
    setScrollToMilestone(state, isScroll) {
        state.scrollToMilestone = isScroll
    }
}

export default { namespaced: true, state, mutations }