const state = {
    // 菜单列表
    menuList: [
        { kind: 2, title: "业绩动态", subTitle: "Performance Dynamics" },
        { kind: 1, title: "公司新闻", subTitle: "Company News" },
    ],

    // 业绩动态数据
    dynamicList: [],
    // 公司新闻数据
    companyNewList: [],

    // 没页数量
    pageSize: 7,
    // 当前页
    currentPage: 1,
}

const mutations = {
    setDynamicList(state, list) {
        state.dynamicList = list
    },
    setCompanyNewList(state, list) {
        state.companyNewList = list
    },
    setCurrentPage(state, page) {
        state.currentPage = page
    },
}

export default { namespaced: true, state, mutations }