const state = {
    // 新闻详情
    newsInfo: {},

    // 新闻类型名称
    newsKindName: '',

    // 当前显示内容类型的所有新闻
    dataList: []
}

const mutations = {
    setNewsInfo(state, info) {
        if (info.kind == 1) {
            state.newsKindName = '公司新闻'
        } else if (info.kind == 2) {
            state.newsKindName = '业绩动态'
        } else {
            state.newsKindName = ''
        }
        state.newsInfo = info
    },

    setNewsKindName(state, name) {
        state.newsKindName = name
    },

    setDataList(state, list) {
        state.dataList = list
    }

}


export default { namespaced: true, state, mutations }