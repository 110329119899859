import { searchDeviceList } from "@/api/main";

const state = {
    // 输入框文本
    keyWord: "",

    // 搜索文本
    searchText: "",

    // 热门产品列表
    hotProductList: [],

    // 搜索结果
    resultList: []
}

const mutations = {
    setKeyWord(state, text) {
        state.keyWord = text
    },

    setSearchText(state, text) {
        state.searchText = text
    },

    setHotProductList(state, list) {
        state.hotProductList = list
    },

    setResultList(state, list) {
        state.resultList = list
    }
}

const actions = {
    search(context) {
        searchDeviceList({ content: state.keyWord }).then(res => {
            if (res.code === 200) {
                const list = res.data || [];
                context.commit('setSearchText', state.keyWord)
                context.commit('setResultList', list)
            }
        })
    }
}

export default { namespaced: true, state, mutations, actions }