const state = {
    // 输入框文本
    keyWord: '',
    // 智能制造设备列表
    deviceList: [],
    // 联系我们电话
    telnumber: ''
}

const mutations = {
    setKeyWord(state, word) {
        state.keyWord = word
    },
    setDeviceList(state, list) {
        state.deviceList = list
    },
    setTelnumber(state, num) {
        state.telnumber = num
    }
}


export default { namespaced: true, state, mutations }