import achievement from './modules/achievement'
import brief from './modules/brief'

const state = {
    // 公司信息
    companyData: {},

    // 菜单列表
    menuList: [
        { type: 1, label: "简介" },
        { type: 2, label: "资质" },
        { type: 3, label: "核心价值观" },
        { type: 4, label: "业绩" }
    ]

}

const mutations = {
    setCompanyData(state, data) {
        state.companyData = data
    }
}

export default { namespaced: true, state, mutations, modules: { brief, achievement } }