<template>
  <div class="header">
    <web class="web"></web>
    <pad class="pad"></pad>
    <phone class="phone"></phone>
  </div>
</template>
<script>
import web from "./web/index.vue";
import pad from "./pad/index";
import phone from "./phone/index.vue";
export default {
  components: { web, pad, phone },
  watch: {
    $route(to, from) {
      const nav = this.$store.state.header.navList.find((item) => {
        return item.name === to.name;
      });
      if (nav) {
        this.$store.commit("header/setSelectNav", nav);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.header
  @media screen and (min-width: 1200px)
    .phone
      display: none
    .pad
      display: none
    .web
      display: flex
  @media screen and (max-width: 1200px)
    .phone
      display: none
    .pad
      display: flex
    .web
      display: none

  @media screen and (max-width: 767px)
    .phone
      display: flex
    .pad
      display: none
    .web
      display: none
</style>
