<template>
  <div class="pad">
    <div class="top">
      <div class="search-label">寻找您想了解的信息</div>
      <div class="search-input">
        <input
          ref="inputView"
          type="text"
          v-model="keyWord"
          @keyup.enter="search"
          placeholder="搜索产品/产品型号"
        />
        <i class="iconfont" @click="search">&#xe6e1;</i>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-item">
        <div class="bottom-item-title">材料行业 智能感知设备</div>
        <div class="bottom-item-content">
          <div @click="toProductList(1)">焦粒炭素材料检测设备</div>
          <div @click="toProductList(2)">炭素制品检测设备</div>
          <div @click="toProductList(3)">焦化沥青类检测设备</div>
          <div @click="toProductList(4)">制样取样生产设备</div>
          <div @click="toProductList(5)">在线检测设备</div>
          <div @click="toProductList(6)">其他设备</div>
        </div>
      </div>
      <div class="bottom-item">
        <div class="bottom-item-title">智能制造 解决方案</div>
        <div class="bottom-item-content">
          <div
            v-for="(item, index) in deviceList"
            :key="'hot-bottom-device' + index"
            @click="lookDeviceInfo(item)"
          >
            {{ item.productname }}
          </div>
        </div>
      </div>
      <div class="bottom-item">
        <div class="bottom-item-title">关于我们</div>
        <div class="bottom-item-content">
          <div @click="toAbout('公司介绍')">公司介绍</div>
          <div @click="toAbout('研发历程')">研发历程</div>
        </div>
      </div>
      <div class="bottom-item">
        <div class="bottom-item-title">联系我们</div>
        <div class="bottom-item-content">
          <div>{{ telnumber }}</div>
        </div>
      </div>
    </div>

    <div class="copyright">
      <div>
        <span>法律</span>
        <span>|</span>
        <span>隐私</span>
      </div>
      <div>
        <span>北京英斯派克科技有限公司</span>
        <span>©2006-2020</span>
        <span>京ICP备13041446号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    keyWord: {
      get() {
        return this.$store.state.footer.keyWord;
      },
      set(value) {
        this.$store.commit("footer/setKeyWord", value);
      },
    },
    ...mapState({
      deviceList: (state) => state.footer.deviceList,
      telnumber: (state) => state.footer.telnumber,
    }),
  },
  methods: {
    // 跳转设备列表
    toProductList(type) {
      this.$store.commit("product/setProductKind", 1);
      this.$store.commit("product/setOpenDeviceType", type);
      if (this.$route.name !== "product") {
        this.$router.push({ path: `/product` });
      } else {
        scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    search() {
      this.$store.commit("search/setKeyWord", this.keyWord);
      this.$store.commit("search/setSearchText", "");
      this.$store.commit("search/setResultList", []);
      this.$store.dispatch("search/search");
      this.keyWord = "";
      this.$refs.inputView.blur();
      if (this.$route.path !== "/search") {
        this.$router.push({ path: "/search" });
      }
    },
    // 查看设备详情
    lookDeviceInfo(device) {
      if (
        this.$route.name === "productInfo" &&
        this.$route.params.id == device.id
      ) {
        scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.$store.commit(
          "productInfo/setDevice",
          JSON.parse(JSON.stringify(device))
        );
        this.$router.push({ path: `/productInfo/${device.id}` });
      }
    },

    // 跳转到关于我们
    toAbout(text) {
      if (this.$route.fullPath != "/about/1") {
        this.$router.push({ path: `/about/1` });
      }
      if (text === "研发历程") {
        this.$store.commit("about/brief/setScrollToMilestone", true);
      } else {
        scrollTo({ top: 0, behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.pad
  background: #000000
  flex-direction: column
  .top
    height: 356px
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    border-bottom: 1px solid #FFFFFF60
    .search-label
      font-size: 34px
      margin-bottom: 41.3px
      color: #FFFFFF
    .search-input
      height: 56px
      width: 737px
      padding: 0 22px
      border-radius: 28px
      background: #ffffff
      overflow: hidden
      display: flex
      align-items: center
      input
        flex: 1
        font-size: 26px
      .iconfont
        font-size: 38px
        cursor: pointer
        &:active
          color: #555555
  .bottom
    padding: 90px 251px 70px 247px
    display: flex
    justify-content: space-between
    .bottom-item
      .bottom-item-title
        height: 56px
        font-size: 30px
        margin-bottom: 32px
        color: #FFFFFF
        font-weight: bold
        display: flex
        align-items: center
        justify-content: center

      .bottom-item-content
        display: flex
        flex-direction: column
        align-items: center
        div
          color: #FFFFFF
          font-size: 26px
          margin-bottom: 19px
          cursor: pointer
          &:last-child
            margin-bottom: 0
      &:last-child
        .bottom-item-title
          font-size: 34px
          border-radius: 28px
          border: 1px solid #FFFFFF
        .bottom-item-content
          div
            font-size: 32px
            cursor: auto
  .copyright
    padding: 0 247px
    margin-bottom: 43px
    display: flex
    align-items: center
    justify-content: space-between
    div
      &:nth-of-type(1)
        span
          font-size: 28px
          font-weight: bold
          &:nth-of-type(2)
            margin: 0 1em
    div
      &:nth-of-type(2)
        span
          font-size: 23px
          &:nth-of-type(2)
            margin: 0 1em
    span
      color: #FFF
</style>
