import Vue from 'vue'
import Vuex from 'vuex'
import header from './modules/header';
import footer from './modules/footer';
import home from './modules/home';
import product from './modules/product';
import achievement from './modules/achievement'
import contact from './modules/contact';
import about from './modules/about';
import search from './modules/search';
import newsInfo from './modules/newsInfo';
import productInfo from './modules/productInfo';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { header, footer, home, product, about, achievement, search, contact, newsInfo, productInfo }
})
