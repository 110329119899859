<template>
  <div class="web">
    <div class="welcome">欢迎来到北京英斯派克科技有限公司</div>
    <div class="logoView">
      <div class="left" @click="backToHome">
        <div class="logo-image"></div>
        <div class="logo-name">北京英斯派克科技有限公司</div>
      </div>
      <div class="right">
        <i class="iconfont" @click="search">&#xe6e1;</i>
      </div>
    </div>
    <div class="navView" v-show="$route.path != '/search'">
      <div
        class="nav-item"
        :class="{ 'nav-item-active': item.label === selectNav.label }"
        v-for="(item, index) in navList"
        :key="'nav-item' + index"
        @click="navItemClicked(item)"
      >
        <div class="nav-item-name">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      navList: (state) => state.header.navList,
      selectNav: (state) => state.header.selectNav,
    }),
  },
  methods: {
    // 点击导航
    navItemClicked(nav) {
      if (
        this.selectNav.path !== nav.path ||
        this.$route.name != this.selectNav.name
      ) {
        if (nav.label === "关于我们") {
          this.$router.push({ path: `/about/1` });
        } else if (nav.label === "业绩动态") {
          this.$router.push({ path: `/achievement/2` });
        } else {
          this.$router.push(nav.path);
        }
      }
    },

    // 回到首页
    backToHome() {
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      }
    },

    // 搜索
    search() {
      if (this.$route.path !== "/search") {
        this.$router.push({ path: "/search" });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.web
  left: 0
  top: 0
  width: 100%
  z-index: 100
  position: fixed
  display: flex
  flex-direction: column
  // 欢迎
  .welcome
    height: 30px
    font-size: 12px
    padding-left: 90px
    color: #FFFFFF
    background: #000000
    display: flex
    align-items: center
  // logo
  .logoView
    height: 65px
    padding: 0 90px
    background: #FFFFFF
    display: flex
    align-items: center
    justify-content: space-between
    border-bottom: 1px solid #DCDCDC
    .left
      cursor: pointer
      display: flex
      align-items: center
      .logo-image
        width: 33px
        height: 33px
        background-image: url('../../../assets/images/logo.jpg')
        background-position: center center
        background-size: contain
        background-repeat: no-repeat
      .logo-name
        margin-left: 5px
        font-size: 16px
        font-weight: bold
    .right
      display: flex
      align-items: center
      .iconfont
        width: 49px
        height: 30px
        font-size: 24px
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        &:active
          color: #73777A
  // 导航
  .navView
    height: 80px
    background: #FFFFFF
    display: flex
    align-items: center
    justify-content: center
    .nav-item
      height: 80px
      width: 120px
      margin-right: 150px
      display: flex
      justify-content: center
      align-items: flex-end
      text-align: center
      &:last-child
        margin-right: 0
      .nav-item-name
        height: 35px
        font-size: 20px
        font-weight: bold
        cursor: pointer
        display: inline-block
        position: relative
        &:after
          content: ' '
          position: absolute
          width: 0
          height: 2px
          left: 50%
          bottom: 0
          background: #595959
          transition: all 0.3s
        &:hover
          color: #007bff
          &:after
            left: 0
            width: 100%
    .nav-item-active
      .nav-item-name
        color: #007bff
        &:after
          left: 0
          width: 100%
</style>
