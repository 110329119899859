<template>
  <div class="phone">
    <div class="header">
      <div class="left" @click="toHome"></div>
      <div class="right">
        <i class="iconfont" @click="toSearch">&#xe6e1;</i>
        <i
          class="iconfont"
          :style="{ opacity: showMenu ? 0 : 1 }"
          @click="showMenu = true"
        >
          &#xe7f4;
        </i>
      </div>
    </div>
    <div class="navView" v-show="showMenu">
      <div class="close">
        <i class="iconfont" @click="showMenu = false">&#xe7fc;</i>
      </div>
      <div class="nav-list">
        <div
          class="nav-item"
          :class="{ 'nav-item-active': item.label === selectNav.label }"
          v-for="item in navList"
          @click="navItemClicked(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState({
      navList: (state) => state.header.navList,
      selectNav: (state) => state.header.selectNav,
    }),
  },
  watch: {
    showMenu(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      var w = document.documentElement.clientWidth;
      if (w > 768) {
        this.showMenu = false;
      }
    });
  },
  methods: {
    toHome() {
      if (this.$route.path !== "/home") {
        this.$router.push("/home");
      }
    },
    toSearch() {
      if (this.$route.path !== "/search") {
        this.$router.push("/search");
      }
    },
    // 点击导航
    navItemClicked(nav) {
      this.showMenu = false;
      if (
        this.selectNav.path !== nav.path ||
        this.$route.name != this.selectNav.name
      ) {
        if (nav.label === "关于我们") {
          this.$router.push({ path: `/about/1` });
        } else if (nav.label === "业绩动态") {
          this.$router.push({ path: `/achievement/2` });
        } else {
          this.$router.push(nav.path);
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.phone
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 230px
  z-index: 9999
  background: #040404
  .header
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    .left
      width: 153.6px
      height: 153.6px
      margin-left: 36px
      cursor: pointer
      background-position: center center
      background-size: contain
      background-repeat: no-repeat
      background-image: url('../../../assets/images/logo-phone.jpg')
    .right
      margin-right: 87px
      display: flex
      align-items: center
      .iconfont
        font-size: 100px
        color: #ffffff
        cursor: pointer
        &:first-child
          font-size: 120px
          margin-right: 128px
          margin-top: 8px
  .navView
    width: 100%
    height: 100vh
    position: absolute
    left: 0
    top: 0
    background: rgba(0,0,0,0.85)
    display: flex
    flex-direction: column
    align-items: center
    .close
      width: 100%
      height: 230px
      padding-right: 87px
      cursor: pointer
      display: flex
      align-items: center
      justify-content: flex-end
      .iconfont
        font-size: 100px
        color: #FFFFFF
        cursor: pointer
    .nav-list
      flex: 1
      display: flex
      align-items: center
      flex-direction: column
      justify-content: center
      .nav-item
        font-size: 88px
        color: #FFFFFF
        margin-bottom: 180px
        cursor: pointer
        &:active
          opacity: 0.8
      .nav-item-active
        color: #0185FF
</style>
