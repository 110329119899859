<template>
  <div class="footer">
    <web class="web"></web>
    <pad class="pad"></pad>
    <phone class="phone"></phone>
  </div>
</template>
<script>
import web from "./web/index.vue";
import pad from "./pad/index.vue";
import phone from "./phone/index.vue";
import { companyInfo, getHotDeviceList } from "@/api/main";
export default {
  components: { web, pad, phone },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      companyInfo().then((res) => {
        if (res.code === 200) {
          this.$store.commit("footer/setTelnumber", res.data.telnumber || "");
        }
      });
      getHotDeviceList().then((res) => {
        if (res.code === 200) {
          const list = res.data || [];
          this.$store.commit(
            "footer/setDeviceList",
            list.filter((item) => {
              return item.kind == 2;
            })
          );
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.footer
  @media screen and (min-width: 1200px)
    .phone
      display: none
    .pad
      display: none
    .web
      display: flex
  @media screen and (max-width: 1200px)
    .phone
      display: none
    .pad
      display: flex
    .web
      display: none

  @media screen and (max-width: 767px)
    .phone
      display: flex
    .pad
      display: none
    .web
      display: none
</style>
