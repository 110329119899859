const state = {

    // 菜单列表
    menuList: [
        { key: "1", name: "材料行业", subName: "智能感知设备" },
        { key: "2", name: "智能制造", subName: "解决方案" },
        { key: "3", name: "环境保护", subName: "环保设备" },
    ],

    // 设备类型列表
    deviceTypeList: [
        { type: 1, label: "焦粒碳素材料检测设备", open: false },
        { type: 2, label: "炭素制品检测设备", open: false },
        { type: 3, label: "焦化沥青类检测设备", open: false },
        { type: 4, label: "制样取样生产设备", open: false },
        { type: 5, label: "在线检测设备", open: false },
        { type: 6, label: "其他设备", open: false },
    ],

    // 设备种类
    productKind: '1',
    // 当前显示的设备列表
    productList: [],

    // 材料行业设备列表
    materialDeviceList: [],
    // 智能制造设备列表
    intelligentDeviceList: [],
    // 环境保护设备列表
    environmentDeviceList: [],

    // 开启设备类型
    openDeviceType: '',

    // 设备分类列表
    typeDeviceList: [],

    // web端当前页
    pageNum: 1
}

const mutations = {
    setProductKind(state, kind) {
        state.productKind = kind
        if (kind == 1) {
            state.productList = state.materialDeviceList
        } else if (kind == 2) {
            state.productList = state.intelligentDeviceList
        } else if (kind == 3) {
            state.productList = state.environmentDeviceList
        }
    },
    setProductList(state, list) {
        const materialDeviceList = list.filter(item => {
            return item.kind == 1
        })
        const intelligentDeviceList = list.filter(item => {
            return item.kind == 2
        })
        const environmentDeviceList = list.filter(item => {
            return item.kind == 3
        })
        state.materialDeviceList = materialDeviceList
        state.intelligentDeviceList = intelligentDeviceList
        state.environmentDeviceList = environmentDeviceList
        state.productList = materialDeviceList
        if (!state.typeDeviceList.length) {
            state.typeDeviceList = state.materialDeviceList.filter((device) => {
                if (device.type) {
                    const list = device.type.split(',');
                    return list.findIndex((typeItem) => typeItem == 1) > -1
                } else {
                    return false;
                }
            })
        }

    },
    setOpenDeviceType(state, type) {
        state.openDeviceType = type
        if (type === 5) {
            state.typeDeviceList = state.intelligentDeviceList.filter(device => {
                return device.id == 28
            })
        } else {
            state.typeDeviceList = state.materialDeviceList.filter((device) => {
                if (device.type) {
                    const list = device.type.split(',');
                    return list.findIndex((typeItem) => typeItem == type) > -1
                } else {
                    return false;
                }
            })
        }
    },
    setPageNum(state, num) {
        state.pageNum = num
    }
}


export default { namespaced: true, state, mutations }